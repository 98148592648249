@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215, 16%, 47%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 221, 83%, 53%;
    --primary-hover: 224, 76%, 48%;
    --primary-foreground: 210, 40%, 98%;

    --secondary: 210 40% 96.1%;
    --secondary-hover: 214, 32%, 91%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 212, 96%, 78%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;

    --primary: 210 40% 98%;
    --primary-hover: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondaty-hover: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  img {
    @apply inline-block;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.rpv-core__page-layer::after {
  box-shadow: none !important;
}

.rpv-thumbnail__list--vertical {
  padding: 0 !important;
}

.rpv-thumbnail__container {
  box-shadow: none !important;
}

.rpv-core__inner-page {
  width: 99% !important;
}

.markdown p {
  margin: 1em 0;
}

.markdown h1 {
  font-size: 1rem;
  display: block;
  margin: 0.67em 0;
  font-weight: bold;
}

.markdown h2 {
  font-size: 1.5rem;
  display: block;
  margin: 0.83em 0;
  font-weight: bold;
}

.markdown h3 {
  font-size: 1.25rem;
  display: block;
  margin: 1em 0;
  font-weight: bold;
}

.markdown h4 {
  font-size: 1rem;
  display: block;
  margin: 1.33em 0;
  font-weight: bold;
}

.markdown h5 {
  font-size: 0.875rem;
  display: block;
  margin: 1.67em 0;
  font-weight: bold;
}

.markdown h6 {
  font-size: 0.75rem;
  display: block;
  margin: 2.33em 0;
  font-weight: bold;
}

.markdown ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 2rem;
}

.main-container {
  width: 100%;
}

@media screen and (width >= 1440px) {
  .main-container {
    width: 1440px;
  }
}

.rpv-page-navigation__current-page-input {
  width: 100%;
}

.rpv-page-navigation__current-page-input .rpv-core__textbox {
  width: 100%;
}

:root:has(.no-doc-scroll) {
  overflow: hidden;
}
